import React from "react";

interface Props {
  children: React.ReactNode;
}

const FormLabel: React.FC<Props> = ({ children }: Props) => {
  return <p className="text-sm my-2 uppercase">{children}</p>;
};

export default FormLabel;
