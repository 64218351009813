import React from "react";
import ReactDOM from "react-dom";
import { observer } from "mobx-react";
// stores
import loadingStore from "../../stores/LoadingStore";
// components
import { FileModel } from "../../models/File";
import FileBox from "./FileBox";
import Button from "../UI/Button";
import DirectoryPathMenu from "./DirectoryPathMenu";
import { loadFilesList } from "../../functions/files/files-list";
import Loading from "../UI/Loading";
import UploadFile from "./UploadFile";
import CreateDirectory from "./CreateDirectory";

interface Props {
  onClose: React.MouseEventHandler;
  onSetImageUrl: React.Dispatch<React.SetStateAction<string>>;
}

const FilesList: React.FC<Props> = observer(
  ({ onClose, onSetImageUrl }: Props) => {
    const [files, filesSet] = React.useState<FileModel[]>([]);
    const [path, pathSet] = React.useState<string>("/");

    const onLoad = async (pathToLoad: string) => {
      filesSet([]);
      filesSet(await loadFilesList(pathToLoad));
    };

    React.useEffect(() => {
      onLoad(path);
    }, [path]);

    const listItems = files.map((file) => (
      <FileBox
        key={Math.random()}
        file={file}
        changePath={pathSet}
        onSetImageUrl={onSetImageUrl}
      />
    ));

    React.useEffect(() => {
      ReactDOM.render(listItems, document.getElementById("fileslist"));
    }, [listItems]);

    const refreshHandler = () => {
      onLoad(path);
    };

    return (
      <div className="flex flex-col h-full w-full">
        <div className="flex flex-row justify-between mx-2 px-2">
          <div className="flex flex-row ">
            <UploadFile path={path} onUpload={refreshHandler} />
            <CreateDirectory path={path} onCreate={refreshHandler} />
          </div>
          <div className="flex flex-row">
            <Button name="zamknij" onClick={onClose} />
          </div>
        </div>
        <div className="flex flex-row mx-2 px-2">
          <DirectoryPathMenu path={path} changePath={pathSet} />
        </div>
        {loadingStore.loading.files && <Loading title="Wczytywanie..." />}
        <div
          id="fileslist"
          className=" m-2 flex flex-row flex-wrap overflow-x-auto flex-grow justify-items-start"
        />
      </div>
    );
  }
);

export default FilesList;
