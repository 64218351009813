import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
// stores
import teammembersStore from "../../stores/TeammembersStore";
// models
import { TeammemberModel } from "../../models/Teammember";
// components
import TeammemberForm from "./TeammemberForm";
import loadingStore from "../../stores/LoadingStore";
import Loading from "../UI/Loading";

const EditTeammember = observer(() => {
  const routerParams = useParams();
  const [teammember, teammemberSet] = React.useState<TeammemberModel>();
  const [errotMsg, errorMsgSet] = React.useState<string>("");
  const history = useHistory();

  const loadTeammember = React.useCallback(async () => {
    if (routerParams.hasOwnProperty("id")) {
      const id: number = (routerParams as any).id;
      const tm = await teammembersStore.loadTeammemberById(id);
      teammemberSet(tm);
    }
  }, [routerParams]);

  React.useEffect(() => {
    loadTeammember();
  }, [loadTeammember]);

  const onFinisHandler = async ({
    id,
    image,
    name,
    type,
    intro,
    surname,
    role,
    active,
    description,
    gallery,
  }: TeammemberModel): Promise<void> => {
    const msg = await teammembersStore.editTeammember({
      id: id,
      image: image,
      name: name,
      type: type,
      intro: intro,
      surname: surname,
      role: role,
      active: active,
      description: description,
      gallery: gallery,
    });
    if (msg.status === "error") {
      errorMsgSet(msg.message);
    } else {
      history.push("/teammembers");
    }
  };

  if (loadingStore.loading.teammember) {
    return <Loading title="Wczytywanie zespołu" />;
  }
  return (
    <>
      {teammember ? (
        <div>
          <TeammemberForm
            teammemberData={teammember!}
            onFinish={onFinisHandler}
          />
          <p className="text-red-400 text-center">{errotMsg}</p>
        </div>
      ) : (
        <p className="text-red-400 text-center">Błąd - nie ma takiej osoby!</p>
      )}
    </>
  );
});

export default EditTeammember;
