import React from "react";
import FilesList from "./FilesList";

interface Props {
  onClose: React.MouseEventHandler;
  onSetImageUrl: React.Dispatch<React.SetStateAction<string>>;
}

const ImagePicker: React.FC<Props> = ({ onClose, onSetImageUrl }: Props) => {
  return (
    <div className="justify-center items-center flex overflow-y-auto overflow-x-auto fixed inset-0 z-50 outline-none focus:outline-none bg-opacity-75 bg-gray-300 dark:bg-gray-600 backdrop-filter backdrop-blur ">
      <div className="bg-gray-100 dark:bg-gray-700 w-full md:w-3/4 h-full md:h-3/4 rounded-xl bg-opacity-100">
        <FilesList onClose={onClose} onSetImageUrl={onSetImageUrl} />
      </div>
    </div>
  );
};

export default ImagePicker;
