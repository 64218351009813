import React from "react";

interface Props {
  name: string;
}

const FormButtonSubmit: React.FC<Props> = ({ name }: Props) => {
  return (
    <button
      type="submit"
      className="p-2 my-2 rounded text-sm m-auto uppercase bg-gray-300 text-gray-700"
    >
      {name}
    </button>
  );
};

export default FormButtonSubmit;
