import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Create, Trash } from "react-ionicons";
import { SponsorModel } from "../../models/Sponsor";
import SponsorAction from "./SponsorAction";
import sponsorsStore from "../../stores/SponsorsStore";

interface Props {
  sponsor: SponsorModel;
}

const SponsorActions: React.FC<Props> = ({ sponsor }: Props) => {
  const { id } = sponsor;
  const history = useHistory();

  const onDeleteHandler = async () => {
    if (window.confirm("Na pewno usunąć?")) {
      const msg = await sponsorsStore.deleteSponsor(id);
      if (msg.string === "error") {
        alert(msg.message);
      } else {
        history.push("/sponsors/reload");
      }
    }
  };

  return (
    <div className="w-1/5 flex flex-col justify-between items-end p-2">
      <SponsorAction onClick={() => {}}>
        <Link to={`/sponsor/edit/${id}`}>
          <Create color="#aaaaaa" />
        </Link>
      </SponsorAction>
      <SponsorAction onClick={onDeleteHandler}>
        <Trash color="#aaaaaa" />
      </SponsorAction>
    </div>
  );
};

export default SponsorActions;
