import React from "react";
import SponsorForm from "./SponsorForm";
import { SponsorModel } from "../../models/Sponsor";
import { useHistory } from "react-router-dom";
import sponsorsStore from "../../stores/SponsorsStore";

const NewSponsor = () => {
  const [errotMsg, errorMsgSet] = React.useState<string>("");
  const history = useHistory();

  const defaultImg =
    (process.env.REACT_APP_BASE_URL || "") +
    (process.env.REACT_APP_DEFAULT_ARTICLE_IMG || "");

  const defaultSponsor: SponsorModel = {
    id: 0,
    image: defaultImg,
    name: "",
    description: "",
  };

  const onFinisHandler = async ({
    id,
    image,
    name,
    description,
  }: SponsorModel) => {
    const msg = await sponsorsStore.addSponsor({
      id: id,
      image: image,
      name: name,
      description: description,
    });
    if (msg.status === "error") {
      errorMsgSet(msg.message);
    } else {
      history.push("/sponsors");
    }
  };

  return (
    <div>
      <SponsorForm sponsorData={defaultSponsor} onFinish={onFinisHandler} />
      <p className="text-red-400 text-center">{errotMsg}</p>
    </div>
  );
};

export default NewSponsor;
