import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Create, Eye, EyeOffOutline, Trash } from "react-ionicons";
import { TeammemberModel } from "../../models/Teammember";
import TeammemberAction from "./TeammemberAction";
import teammembersStore from "../../stores/TeammembersStore";

interface Props {
  teammember: TeammemberModel;
}

const TeammemberActions: React.FC<Props> = ({ teammember }: Props) => {
  const { id, active } = teammember;
  const history = useHistory();
  const { pathname } = useLocation();

  const onActiveHandler = async () => {
    const msg = await teammembersStore.toogleActiveTeammember(id);
    if (msg.string === "error") {
      alert(msg.message);
    } else {
      if (pathname === "/teammembers") {
        history.push("/teammembers/reload");
      } else {
        history.push("/teammembers");
      }
    }
  };

  const onDeleteHandler = async () => {
    if (window.confirm("Na pewno usunąć?")) {
      const msg = await teammembersStore.deleteTeammember(id);
      if (msg.string === "error") {
        alert(msg.message);
      } else {
        history.push("/teammembers/reload");
      }
    }
  };

  return (
    <div className="w-1/5 flex flex-col justify-between items-end p-2">
      <TeammemberAction onClick={() => {}}>
        <Link to={`/teammember/edit/${id}`}>
          <Create color="#aaaaaa" />
        </Link>
      </TeammemberAction>
      <TeammemberAction onClick={onActiveHandler}>
        {active ? <Eye color="#aaaaaa" /> : <EyeOffOutline color="#aaaaaa" />}
      </TeammemberAction>
      <TeammemberAction onClick={onDeleteHandler}>
        <Trash color="#aaaaaa" />
      </TeammemberAction>
    </div>
  );
};

export default TeammemberActions;
