import loadingStore from "../../stores/LoadingStore";
import authStore from "../../stores/AuthStore";

export const loadFilesList = async (path: string) => {
  loadingStore.setLoadingFiles(true);
  const response = await fetch(
    process.env.REACT_APP_API_BASE_URL + "/api/files/list?path=" + path,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-access-token": authStore.auth.token,
      },
    }
  ).catch((err) => {
    throw err;
  });
  const resData = await response.json().catch((err) => {
    throw err;
  });

  loadingStore.setLoadingFiles(false);
  return resData;
};
