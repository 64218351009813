import React from "react";

interface Props {
  name: string;
  onClick: React.MouseEventHandler;
}

const ButtonPath: React.FC<Props> = ({ name, onClick }: Props) => {
  return (
    <div className="flex flex-row justify-center text-sm items-center">
      &raquo;
      <button className="hover:underline m-1 p-1" onClick={onClick}>
        {name}
      </button>
    </div>
  );
};

export default ButtonPath;
