import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
// stores
import sponsorsStore from "../../stores/SponsorsStore";
// models
import { SponsorModel } from "../../models/Sponsor";
// components
import SponsorForm from "./SponsorForm";
import loadingStore from "../../stores/LoadingStore";
import Loading from "../UI/Loading";

const EditSponsor = observer(() => {
  const routerParams = useParams();
  const [sponsor, sponsorSet] = React.useState<SponsorModel>();
  const [errotMsg, errorMsgSet] = React.useState<string>("");
  const history = useHistory();

  const loadSponsor = React.useCallback(async () => {
    if (routerParams.hasOwnProperty("id")) {
      const id: number = (routerParams as any).id;
      const spo = await sponsorsStore.loadSponsorById(id);
      sponsorSet(spo);
    }
  }, [routerParams]);

  React.useEffect(() => {
    loadSponsor();
  }, [loadSponsor]);

  const onFinisHandler = async ({
    id,
    image,
    name,
    description,
  }: SponsorModel): Promise<void> => {
    const msg = await sponsorsStore.editSponsor({
      id: id,
      image: image,
      name: name,
      description: description,
    });
    if (msg.status === "error") {
      errorMsgSet(msg.message);
    } else {
      history.push("/sponsors");
    }
  };

  if (loadingStore.loading.teammember) {
    return <Loading title="Wczytywanie zespołu" />;
  }
  return (
    <>
      {sponsor ? (
        <div>
          <SponsorForm sponsorData={sponsor!} onFinish={onFinisHandler} />
          <p className="text-red-400 text-center">{errotMsg}</p>
        </div>
      ) : (
        <p className="text-red-400 text-center">Błąd - nie ma takiej osoby!</p>
      )}
    </>
  );
});

export default EditSponsor;
