import React from "react";
import { Route, Switch } from "react-router-dom";
// components
import AppNameVersion from "../components/UI/AppNameVersion";
import NotFoundScreen from "../screens/NotFoundScreen";
import ArticlesList from "../components/articles/ArticlesList";
import NewArticle from "../components/articles/NewArticle";
import EditArticle from "../components/articles/EditArticle";
import FavArticlesList from "../components/articles/FavArticlesList";
import TeammembersList from "../components/teammembers/TeammembersList";
import NewTeammember from "../components/teammembers/NewTeammember";
import EditTeammember from "../components/teammembers/EditTeammember";
import SponsorsList from "../components/sponsors/SponsorsList";
import NewSponsor from "../components/sponsors/NewSponsor";
import EditSponsor from "../components/sponsors/EditSponsor";

const ContentRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={AppNameVersion} />

      <Route exact path="/articles" component={ArticlesList} />
      <Route exact path="/articles/reload" component={ArticlesList} />
      <Route exact path="/articles/favourites" component={FavArticlesList} />
      <Route
        exact
        path="/articles/favourites/reload"
        component={FavArticlesList}
      />
      <Route exact path="/article/edit/:id" component={EditArticle} />
      <Route path="/article/add" component={NewArticle} />

      <Route exact path="/teammembers" component={TeammembersList} />
      <Route exact path="/teammembers/reload" component={TeammembersList} />
      <Route exact path="/teammember/add" component={NewTeammember} />
      <Route exact path="/teammember/edit/:id" component={EditTeammember} />

      <Route exact path="/sponsors" component={SponsorsList} />
      <Route exact path="/sponsors/reload" component={SponsorsList} />
      <Route exact path="/sponsor/add" component={NewSponsor} />
      <Route exact path="/sponsor/edit/:id" component={EditSponsor} />

      <Route path="*" component={NotFoundScreen} />
    </Switch>
  );
};

export default ContentRoutes;
