import React from "react";
import { uploadFile } from "../../functions/files/upload-file";
import Button from "../UI/Button";

interface Props {
  path: string;
  onUpload: React.Dispatch<void>;
}

const UploadFile: React.FC<Props> = ({ path, onUpload }: Props) => {
  const [fileToUpload, fileToUploadSet] = React.useState<string>("");

  const onButtonToInputHandler = () => {
    const getFile = document.getElementById("inputFile");
    getFile?.click();
  };

  const onUploadFile = (file: any) => {
    uploadFile(path, file).then((msg) => {
      if (msg.status === "success") {
        fileToUploadSet("");
      } else {
        alert(msg.message);
      }
      onUpload();
    });
  };

  return (
    <div>
      <input
        id="inputFile"
        value={fileToUpload}
        name="upload"
        className="hidden"
        type="file"
        accept="image/jpeg, image/png, image/jpg"
        onChange={(event) => {
          onUploadFile(event.target.files);
        }}
      />
      <Button onClick={onButtonToInputHandler} name="Upload pliku" />
    </div>
  );
};

export default UploadFile;
