import React from "react";

interface Props {
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
}

const TeammemberAction: React.FC<Props> = ({ children, onClick }: Props) => {
  return <button onClick={onClick}>{children}</button>;
};

export default TeammemberAction;
