import React from "react";
import { Eye, EyeOffOutline } from "react-ionicons";

interface Props {
  active: number;
  activeSet: React.Dispatch<number>;
}

const TeammemberActive: React.FC<Props> = ({ active, activeSet }: Props) => {
  const toogleHandler = () => {
    activeSet(1 - active);
  };

  return active ? (
    <div className="flex mt-4 mx-2 p-0 cursor-pointer">
      <Eye onClick={toogleHandler} color="#aaaaaa" />
    </div>
  ) : (
    <div className="flex mt-4 mx-2 p-0 cursor-pointer">
      <EyeOffOutline onClick={toogleHandler} color="#aaaaaa" />
    </div>
  );
};

export default React.memo(TeammemberActive);
