import React from "react";
import { Trash } from "react-ionicons";
import FormImage from "./FormImage";

interface Props {
  images: string[];
  onFinish: any;
}

const FormGallery: React.FC<Props> = ({ images, onFinish }: Props) => {
  const [galleryImages, galleryImagesSet] = React.useState<string[]>(images);
  const [imgToAdd, imgToAddSet] = React.useState<string>("");

  React.useEffect(() => {
    if (imgToAdd !== "") {
      galleryImagesSet(galleryImages.filter((item) => item !== imgToAdd));
      galleryImagesSet((prev) => [...prev, imgToAdd]);
      imgToAddSet("");
    }
  }, [imgToAdd]);

  const onDeleteHandler = (image: string) => {
    galleryImagesSet(galleryImages.filter((item) => item !== image));
  };

  const onChangeHandler = (oldImage: string, newImage: string) => {
    if (oldImage !== newImage) {
      imgToAddSet(newImage);
      galleryImagesSet(galleryImages.filter((item) => item !== oldImage));
    }
  };

  React.useEffect(() => {
    onFinish(galleryImages);
  }, [galleryImages]);

  return (
    <div className="w-full grid grid-cols-2 gap-1">
      {galleryImages.map((item) => {
        return (
          <div key={Math.random()} className="relative">
            <FormImage
              image={item}
              imageSet={(newImg) => onChangeHandler(item, newImg.toString())}
              info="zdjęcie do galeri"
            />
            <div className="bg-gray-900 opacity-75 p-2 absolute top-0 right-0">
              <button
                onClick={() => {
                  onDeleteHandler(item);
                }}
              >
                <Trash color="#aaaaaa" />
              </button>
            </div>
          </div>
        );
      })}
      <FormImage
        key={Math.random()}
        image=""
        imageSet={imgToAddSet}
        info="zdjęcie do galeri"
      />
    </div>
  );
};

export default React.memo(FormGallery);
