import React from "react";
import { Star, StarOutline } from "react-ionicons";

interface Props {
  fav: number;
  favSet: React.Dispatch<React.SetStateAction<number>>;
}

const FavArticle: React.FC<Props> = ({ fav, favSet }: Props) => {
  const toogleHandler = () => {
    favSet(1 - fav);
  };

  return fav ? (
    <div className="flex m-2 p-2 cursor-pointer">
      <Star onClick={toogleHandler} color="#777777" />
    </div>
  ) : (
    <div className="flex m-2 p-2 cursor-pointer">
      <StarOutline onClick={toogleHandler} color="#777777" />
    </div>
  );
};

export default React.memo(FavArticle);
