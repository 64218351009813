import React from "react";
import { observer } from "mobx-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// stores
import authStore from "./stores/AuthStore";
// screens
import AuthScreen from "./screens/AuthScreen";
import HomeScreen from "./screens/HomeScreen";
import NotFoundScreen from "./screens/NotFoundScreen";

const App = observer(() => {
  return (
    <BrowserRouter>
      <div className="justify-center flex p-4 min-h-screen bg-transparent dark:bg-gray-700">
        {authStore.isAuth() ? (
          <Switch>
            <Route path="/" component={HomeScreen} />
            <Route path="*" component={NotFoundScreen} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={AuthScreen} />
            <Route path="*" component={NotFoundScreen} />
          </Switch>
        )}
      </div>
    </BrowserRouter>
  );
});

export default App;
