import React, { FormEvent } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
// stores
import authStore from "../../stores/AuthStore";
import loadingStore from "../../stores/LoadingStore";
// components
import FormLabel from "../UI/FormLabel";
import FormButtonSubmit from "../UI/FormButtonSubmit";
import LoginFormInput from "../UI/LoginFormInput";
import Loading from "../UI/Loading";

const LoginForm = observer(() => {
  const [username, usernameSet] = React.useState("");
  const [password, passwordSet] = React.useState("");
  const [message, messageSet] = React.useState("");
  const history = useHistory();

  const formSubmitHandler = async (event: FormEvent) => {
    event.preventDefault();
    const { status, message } = await authStore.login(username, password);
    if (status === "error") {
      messageSet(message);
    } else {
      history.push("/");
    }
  };

  return (
    <div className="text-2xl text-center p-12 m-auto rounded-lg border-2 border-gray-300 bg-transparent text-gray-700 dark:text-gray-300">
      <form onSubmit={(event) => formSubmitHandler(event)} method="post">
        <h1 className="uppercase underline">Logowanie</h1>
        <FormLabel>Użytkownik</FormLabel>
        <LoginFormInput
          name="username"
          value={username}
          set={usernameSet}
          type="text"
          placeholder="podaj swój login"
        />
        <FormLabel>Hasło</FormLabel>
        <LoginFormInput
          name="password"
          value={password}
          set={passwordSet}
          type="password"
          placeholder="podaj swoje hasło"
        />
        <FormButtonSubmit name="Zaloguj" />
        {loadingStore.loading.login && <Loading title="Logowanie..." />}
        <p className="text-red-500 text-base">{message}</p>
      </form>
    </div>
  );
});

export default LoginForm;
