import React from "react";
// components
import ArticleForm from "./ArticleForm";
import { ArticleModel } from "../../models/Article";
import articlesStore from "../../stores/ArticlesStore";
import { useHistory } from "react-router-dom";

const NewArticle = () => {
  const history = useHistory();
  const [errotMsg, errorMsgSet] = React.useState<string>("");

  const defaulltImage = String.prototype.concat(
    process.env.REACT_APP_BASE_URL || "",
    process.env.REACT_APP_DEFAULT_ARTICLE_IMG || ""
  );

  const articleData: ArticleModel = {
    id: 0,
    image: defaulltImage,
    title: "",
    favourite: 0,
    content: "",
  };

  const onFinishHandler = async ({
    id,
    title,
    content,
    image,
    favourite,
  }: ArticleModel): Promise<void> => {
    const msg = await articlesStore.addArticle({
      id: id,
      image: image,
      favourite: favourite,
      content: content,
      title: title,
    });
    if (msg.status === "error") {
      errorMsgSet(msg.message);
    } else {
      history.push("/articles/reload");
    }
  };

  return (
    <div>
      <ArticleForm
        articleData={articleData}
        onFinish={(data: ArticleModel) => onFinishHandler(data)}
      />
      <p className="text-red-400 text-center">{errotMsg}</p>
    </div>
  );
};

export default NewArticle;
