import { makeAutoObservable } from "mobx";

interface LoadingModel {
  articles: boolean;
  favArticles: boolean;
  article: boolean;
  files: boolean;
  teammembers: boolean;
  teammember: boolean;
  login: boolean;
  sponsors: boolean;
  sponsor: boolean;
}

export class LoadingStore {
  public loading: LoadingModel = {
    articles: false,
    favArticles: false,
    article: false,
    files: false,
    teammembers: false,
    teammember: false,
    login: false,
    sponsors: false,
    sponsor: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  public setLoadingFiles = (status: boolean) => {
    this.loading.files = status;
  };
}

const loadingStore = new LoadingStore();

export default loadingStore;
