import React from "react";
import { Link } from "react-router-dom";
// models
import { SponsorModel } from "../../models/Sponsor";
import SponsorActions from "./SponsorActions";

interface Props {
  sponsor: SponsorModel;
}

const SponsorPreview: React.FC<Props> = ({ sponsor }: Props) => {
  const { id, image, name, description } = sponsor;

  return (
    <div className="flex  w-full rounded overflow-auto border-2 border-gray-300">
      <div className="w-1/5">
        <img src={image} alt={name} className="object-contain h-full w-full" />
      </div>
      <div className="w-3/5 p-2 overflow-hidden flex flex-col justify-between">
        <h1 className="font-bold text-gray-700 dark:text-gray-300">
          <Link to={"/sponsor/edit/" + id}>{name}</Link>
        </h1>
        <h2 className="text-sm text-gray-600 dark:text-gray-200">
          {description}
        </h2>
      </div>
      <SponsorActions sponsor={sponsor} />
    </div>
  );
};

export default SponsorPreview;
