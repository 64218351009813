import authStore from "../../stores/AuthStore";

export const uploadFile = async (path: string, file: FileList) => {
  const fileData = new FormData();
  fileData.append("file", file[0]);
  const response = await fetch(
    process.env.REACT_APP_API_BASE_URL + "/api/file/upload?path=" + path,
    {
      method: "POST",
      headers: {
        "x-access-token": authStore.auth.token,
      },
      body: fileData,
    }
  ).catch((err) => {
    throw err;
  });
  const resData = await response.json().catch((err) => {
    throw err;
  });

  return resData;
};
