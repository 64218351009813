import React from "react";

interface Props {
  name: string;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ name, onClick, disabled }: Props) => {
  return (
    <div className="flex justify-center">
      <button
        className={
          "p-2 mx-1 my-1 rounded text-sm flex m-auto justify-center border-2 border-gray-300" +
          (disabled
            ? " text-gray-300 dark:text-gray-600"
            : " text-gray-700 dark:text-gray-300")
        }
        onClick={onClick}
        disabled={disabled}
      >
        {name}
      </button>
    </div>
  );
};

export default React.memo(Button);
