import React from "react";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

interface Props {
  title?: string;
}

const Loading: React.FC<Props> = ({ title }: Props) => {
  return (
    <div className="text-center text-gray-700 dark:text-gray-300">
      <Dots size={24} />
      <p className="text-sm">{title}</p>
    </div>
  );
};

export default Loading;
