import React from "react";
import { Link } from "react-router-dom";
// models
import { ArticleModel } from "../../models/Article";
import ArticleActions from "./ArticleActions";

interface Props {
  article: ArticleModel;
}

const ArticlePreview: React.FC<Props> = ({ article }: Props) => {
  const { id, image, title } = article;

  return (
    <div className="flex flex-row mt-1 mb-2 h-24 rounded overflow-auto border-2 border-gray-300">
      <div className="w-1/5">
        <img src={image} alt={title} className="object-cover h-full w-full" />
      </div>
      <div className="w-3/5 p-2 break-all overflow-hidden">
        <h1 className="font-bold text-gray-700 dark:text-gray-300">
          <Link to={`/article/edit/${id}`}>{title}</Link>
        </h1>
      </div>
      <ArticleActions article={article} />
    </div>
  );
};

export default ArticlePreview;
