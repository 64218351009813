import React from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
// stores
import sponsorsStore from "../../stores/SponsorsStore";
import loadingStore from "../../stores/LoadingStore";
// components
import Loading from "../UI/Loading";
import SponsorPreview from "./SponsorPreview";

const SponosorsList = observer(() => {
  const { pathname } = useLocation();
  const history = useHistory();

  const loadSponsors = async () => {
    await sponsorsStore.loadSponsors();
  };

  React.useEffect(() => {
    if (pathname === "/sponsors/reload") {
      sponsorsStore.reloadSponsors();
      history.push("/sponsors");
    } else {
      loadSponsors();
    }
  }, [pathname, history]);

  const SponsorsPreviewList = () => {
    return (
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-1">
        {sponsorsStore.sponsors.sponsors.map((item) => (
          <SponsorPreview sponsor={item} key={item.id} />
        ))}
      </div>
    );
  };

  if (loadingStore.loading.sponsors) {
    return <Loading title="Wczytywanie sponsorów" />;
  }

  return (
    <div>
      <SponsorsPreviewList />
    </div>
  );
});

export default SponosorsList;
