import React from "react";
import { ImageOutline } from "react-ionicons";
import ImagePicker from "../files/ImagePicker";

interface Props {
  image: string;
  imageSet: React.Dispatch<React.SetStateAction<string>>;
  info: string;
}

const FormImage: React.FC<Props> = ({ image, imageSet, info }: Props) => {
  const [imageModal, imageModalSet] = React.useState<boolean>(false);
  const [imageUrl, imageUrlSet] = React.useState<string>(image);

  React.useEffect(() => {
    imageModalSet(false);
    imageSet(imageUrl);
  }, [imageUrl, imageSet]);

  return (
    <div>
      <div
        className="text-center justify-center items-center flex h-64 border-2 border-gray-300 cursor-pointer rounded-md bg-white"
        onClick={() => {
          imageModalSet(true);
        }}
      >
        {imageUrl ? (
          <img
            alt={info}
            src={imageUrl}
            className="object-contain h-full w-full"
          />
        ) : (
          <ImageOutline height="64px" width="64px" />
        )}
      </div>
      {imageModal && (
        <ImagePicker
          onClose={() => imageModalSet(false)}
          onSetImageUrl={imageUrlSet}
        />
      )}
    </div>
  );
};

export default React.memo(FormImage);
