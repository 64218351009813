import React from "react";
import { observer } from "mobx-react";
// stores
import articlesStore from "../../stores/ArticlesStore";
import loadingStore from "../../stores/LoadingStore";
// components
import ArticlePreview from "./ArticlePreview";
import Loading from "../UI/Loading";
import Button from "../UI/Button";
import { useHistory, useLocation } from "react-router-dom";

const ArticlesList = observer(() => {
  const { pathname } = useLocation();
  const history = useHistory();

  const loadMoreArticles = async () => {
    await articlesStore.loadArticles();
  };

  React.useEffect(() => {
    if (pathname === "/articles/reload") {
      articlesStore.reloadArticles();
      history.push("/articles");
    } else {
      loadMoreArticles();
    }
  }, [pathname, history]);

  const ArticlePreviewList = () => {
    return (
      <div id="articlelist">
        {articlesStore.articles.articles.map((item) => (
          <ArticlePreview article={item} key={item.id} />
        ))}
      </div>
    );
  };

  return (
    <div>
      <ArticlePreviewList />
      {loadingStore.loading.articles && (
        <Loading title="Wczytywanie artykułów..." />
      )}
      {articlesStore.isAllArticlesLoaded() || (
        <Button name="więcej" onClick={() => loadMoreArticles()} />
      )}
    </div>
  );
});

export default ArticlesList;
