import { makeAutoObservable, runInAction } from "mobx";
import loadingStore from "./LoadingStore";
import { TeammemberModel, TeammembersModel } from "../models/Teammember";
import authStore from "./AuthStore";

export class TeammembersStore {
  public teammembers: TeammembersModel = {
    teammembers: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  public loadTeammembers = async () => {
    loadingStore.loading.teammembers = true;
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/teammembers/all",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      this.teammembers.teammembers = resData;
      loadingStore.loading.teammembers = false;
    });
  };

  public loadTeammemberById = async (teammemberId: number) => {
    loadingStore.loading.teammember = true;
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/teammember/" + teammemberId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      loadingStore.loading.teammember = false;
    });
    return resData[0];
  };

  public addTeammember = async (teammember: TeammemberModel) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/teammember/add",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
        body: JSON.stringify(teammember),
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public editTeammember = async (teammember: TeammemberModel) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/teammember/edit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
        body: JSON.stringify(teammember),
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public toogleActiveTeammember = async (teammemberId: number) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/teammember/active/" +
        teammemberId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public deleteTeammember = async (teammemberId: number) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/teammember/delete/" +
        teammemberId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public reloadTeammembers = () => {
    this.teammembers.teammembers = [];
  };
}

const teammembersStore = new TeammembersStore();

export default teammembersStore;
