import React from "react";
import Button from "../UI/Button";
import {
  createDirectory,
  namePrepare,
} from "../../functions/files/create-directory";

interface Props {
  path: string;
  onCreate: React.Dispatch<void>;
}

const CreateDirectory: React.FC<Props> = ({ path, onCreate }: Props) => {
  const onCreateHandler = () => {
    let propmtText =
      prompt(
        "Jaka nazwa? (małe litery i cyfry, bez polskich znaków i specjalnych)"
      ) || "";
    if (namePrepare(propmtText)) {
      createDirectory(path, namePrepare(propmtText)).then((msg) => {
        if (msg.status === "error") {
          alert(msg.message);
        } else {
          onCreate();
        }
      });
    }
  };

  return <Button onClick={onCreateHandler} name="Nowy folder" />;
};

export default CreateDirectory;
