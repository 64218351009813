import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ArticleModel } from "../../models/Article";
import { Create, Star, StarOutline, Trash } from "react-ionicons";
import ArticleAction from "./ArticleAction";
import articlesStore from "../../stores/ArticlesStore";

interface Props {
  article: ArticleModel;
}

const ArticleActions: React.FC<Props> = ({ article }: Props) => {
  const { id, favourite } = article;
  const history = useHistory();
  const { pathname } = useLocation();

  const onDeleteHandler = async () => {
    if (window.confirm("Na pewno usunąć?")) {
      const msg = await articlesStore.deleteArticle(id);
      if (msg.string === "error") {
        alert(msg.message);
      } else {
        history.push("/articles/reload");
      }
    }
  };

  const onFavouriteHandler = async () => {
    const msg = await articlesStore.toogleFavArticle(id);
    if (msg.string === "error") {
      alert(msg.message);
    } else {
      if (pathname === "/articles/favourites") {
        history.push("/articles/favourites/reload");
      } else {
        history.push("/articles/reload");
      }
    }
  };

  return (
    <div className="w-1/5 flex flex-col justify-between items-end p-2">
      <ArticleAction onClick={() => {}}>
        <Link to={`/article/edit/${id}`}>
          <Create color="#aaaaaa" />
        </Link>
      </ArticleAction>
      <ArticleAction onClick={onFavouriteHandler}>
        {favourite ? <Star color="#aaaaaa" /> : <StarOutline color="#aaaaaa" />}
      </ArticleAction>
      <ArticleAction onClick={onDeleteHandler}>
        <Trash color="#aaaaaa" />
      </ArticleAction>
    </div>
  );
};

export default ArticleActions;
