import { makeAutoObservable, runInAction } from "mobx";
import jwt_decode from "jwt-decode";
import loadingStore from "./LoadingStore";

export interface AuthModel {
  token: string;
}

interface ResponseModel {
  status: string;
  message: string;
}

export class AuthStore {
  public auth: AuthModel = {
    token: "",
  };

  constructor() {
    makeAutoObservable(this);
    this.auth.token = sessionStorage.getItem("token") || "";
  }

  public login = async (
    username: string,
    password: string
  ): Promise<ResponseModel> => {
    loadingStore.loading.login = true;
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/user/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      if (resData.status === "success") {
        this.auth.token = resData.accessToken;
        sessionStorage.setItem("token", resData.accessToken);
      }
      loadingStore.loading.login = false;
    });
    return resData;
  };

  public logout = () => {
    this.auth.token = "";
    sessionStorage.removeItem("token");
  };

  public isAuth = () => {
    if (this.auth.token !== "") {
      const decodedToken = jwt_decode(this.auth.token);
      // @ts-ignore
      if (decodedToken.exp * 1000 < Date.now()) {
        this.logout();
      }
    }
    return this.auth.token !== "";
  };
}

const authStore = new AuthStore();

export default authStore;
