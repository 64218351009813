import React from "react";
import TeammemberForm from "./TeammemberForm";
import { TeammemberModel } from "../../models/Teammember";
import { useHistory } from "react-router-dom";
import teammembersStore from "../../stores/TeammembersStore";

const NewTeammember = () => {
  const [errotMsg, errorMsgSet] = React.useState<string>("");
  const history = useHistory();

  const defaultImg =
    (process.env.REACT_APP_BASE_URL || "") +
    (process.env.REACT_APP_DEFAULT_ARTICLE_IMG || "");

  const defaultTeamMember: TeammemberModel = {
    id: 0,
    image: defaultImg,
    active: 1,
    role: "",
    surname: "",
    name: "",
    description: "",
    intro: "",
    type: 2,
    gallery: [defaultImg],
  };

  const onFinisHandler = async ({
    id,
    image,
    name,
    type,
    intro,
    surname,
    role,
    active,
    description,
    gallery,
  }: TeammemberModel) => {
    const msg = await teammembersStore.addTeammember({
      id: id,
      image: image,
      name: name,
      type: type,
      intro: intro,
      surname: surname,
      role: role,
      active: active,
      description: description,
      gallery: gallery,
    });
    if (msg.status === "error") {
      errorMsgSet(msg.message);
    } else {
      history.push("/teammembers");
    }
  };

  return (
    <div>
      <TeammemberForm
        teammemberData={defaultTeamMember}
        onFinish={onFinisHandler}
      />
      <p className="text-red-400 text-center">{errotMsg}</p>
    </div>
  );
};

export default NewTeammember;
