import React from "react";
import { observer } from "mobx-react";
// stores
import articlesStore from "../../stores/ArticlesStore";
import loadingStore from "../../stores/LoadingStore";
// components
import ArticlePreview from "./ArticlePreview";
import Loading from "../UI/Loading";
import { useHistory, useLocation } from "react-router-dom";

const FavArticlesList = observer(() => {
  const { pathname } = useLocation();
  const history = useHistory();

  const loadFavArticles = async () => {
    await articlesStore.loadFavArticles();
  };

  React.useEffect(() => {
    loadFavArticles();
    if (pathname === "/articles/favourites/reload") {
      history.push("/articles/favourites");
    }
  }, [pathname, history]);

  const ArticlePreviewList = () => {
    return (
      <div id="articlelist">
        {articlesStore.articles.favArticles.map((item) => (
          <ArticlePreview article={item} key={item.id} />
        ))}
      </div>
    );
  };

  return (
    <div>
      <ArticlePreviewList />
      {loadingStore.loading.favArticles && (
        <Loading title="Wczytywanie artykułów..." />
      )}
    </div>
  );
});

export default FavArticlesList;
