import React from "react";

interface Props {
  children: React.ReactNode;
}

const MenuSection: React.FC<Props> = ({ children }: Props) => {
  return (
    <div className="text-center text-sm rounded p-1 m-1 font-bold text-gray-900 dark:text-gray-300 w-full">
      {children}
    </div>
  );
};

export default MenuSection;
