import { makeAutoObservable, runInAction } from "mobx";
import { ArticleModel, ArticlesModel } from "../models/Article";
import loadingStore from "./LoadingStore";
import authStore from "./AuthStore";

export class ArticlesStore {
  public articles: ArticlesModel = {
    articles: [],
    favArticles: [],
    articlesCount: -1,
  };

  private articlesPage: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public loadArticles = async () => {
    loadingStore.loading.articles = true;
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/articles/page/" +
        this.articlesPage,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
    this.articlesPage++;
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      this.addToStoreNotDuplicate(resData);
      loadingStore.loading.articles = false;
    });
  };

  public loadArticleById = async (articleId: number) => {
    loadingStore.loading.article = true;
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/article/" + articleId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      loadingStore.loading.article = false;
    });
    return resData[0];
  };

  public loadFavArticles = async () => {
    loadingStore.loading.favArticles = true;
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/articles/favourites",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      this.articles.favArticles = resData;
      loadingStore.loading.favArticles = false;
    });
  };

  public isAllArticlesLoaded = () => {
    if (this.articles.articlesCount === -1) {
      this.getArticlesCount().then(() => {
        return this.articles.articlesCount === this.articles.articles.length;
      });
    } else {
      return this.articles.articlesCount === this.articles.articles.length;
    }
  };

  private getArticlesCount = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/articles/count",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      this.articles.articlesCount = resData.count;
    });
  };

  public addArticle = async (article: ArticleModel) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/article/add",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
        body: JSON.stringify(article),
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public editArticle = async (article: ArticleModel) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/article/edit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
        body: JSON.stringify(article),
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public deleteArticle = async (articleId: number) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/article/delete/" + articleId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public toogleFavArticle = async (articleId: number) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/article/fav/" + articleId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public reloadArticles = () => {
    this.articles.articles = [];
    this.articlesPage = 0;
    this.articles.articlesCount = -1;
  };

  private addToStoreNotDuplicate = (newArticles: ArticleModel[]) => {
    const storeArticlesIds = this.articles.articles.map((item) => item.id);
    newArticles.forEach((item) => {
      if (!storeArticlesIds.includes(item.id)) {
        this.articles.articles.push(item);
      }
    });
  };
}

const articlesStore = new ArticlesStore();

export default articlesStore;
