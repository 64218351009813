import React from "react";
import { name, version } from "../../../package.json";

const AppNameVersion = () => {
  return (
    <div>
      <p className="text-gray-900 dark:text-gray-300">
        Witaj w {name} (v{version})
      </p>
    </div>
  );
};

export default AppNameVersion;
