import React from "react";
import { pathList } from "../../functions/files/path-list";
import ReactDOM from "react-dom";
import ButtonPath from "../UI/ButtonPath";

interface Props {
  path: string;
  changePath: React.Dispatch<React.SetStateAction<string>>;
}

const DirectoryPathMenu: React.FC<Props> = ({ path, changePath }: Props) => {
  const onClickHandler = (pathToChange: string) => {
    changePath(pathToChange);
  };

  const pathItems = pathList(path).map((item) => (
    <ButtonPath
      name={item.name}
      onClick={() => onClickHandler(item.path)}
      key={Math.random()}
    />
  ));

  React.useEffect(() => {
    ReactDOM.render(pathItems, document.getElementById("pathlist"));
  }, [pathItems]);

  return <div className="flex flex-row justify-center" id="pathlist" />;
};

export default DirectoryPathMenu;
