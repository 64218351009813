import React from "react";
import { useHistory } from "react-router-dom";
import { LogOut } from "react-ionicons";
// stores
import authStore from "../../stores/AuthStore";

const Logout = () => {
  const history = useHistory();

  const logoutHandler = () => {
    authStore.logout();
    history.push("/");
  };

  return (
    <button
      onClick={logoutHandler}
      className="p-1 m-1 w-full flex items-center justify-center"
    >
      <LogOut color="#aaaaaa" />
    </button>
  );
};

export default Logout;
