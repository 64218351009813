import React from "react";
import { Menu } from "react-ionicons";
import MenuRoutes from "../../navigation/MenuRoutes";
import Logout from "./Logout";

const MenuBox = () => {
  const [showMenu, showMenuSet] = React.useState<boolean>(false);

  return (
    <div>
      <div className="flex flex-col md:hidden ">
        <div className="flex flex-col items-end">
          <button
            onClick={() => showMenuSet((prev) => !prev)}
            className="p-1 m-1 border-2 border-gray-300 rounded"
          >
            <Menu color="#aaaaaa" />
          </button>
        </div>
        <div className={"flex-col" + (showMenu ? " flex" : " hidden")}>
          <MenuRoutes />
          <Logout />
        </div>
      </div>
      <div className="hidden md:flex flex-col">
        <MenuRoutes />
        <Logout />
      </div>
    </div>
  );
};

export default MenuBox;
