import React from "react";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  name: string;
}

const MenuLink: React.FC<Props> = ({ to, name }: Props) => {
  return (
    <Link
      to={to}
      className="text-center text-sm rounded p-1 m-1 border-2 border-gray-300 text-gray-700 dark:text-gray-300 w-full"
    >
      {name}
    </Link>
  );
};

export default MenuLink;
