import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
// stores
import articlesStore from "../../stores/ArticlesStore";
import loadingStore from "../../stores/LoadingStore";
// models
import { ArticleModel } from "../../models/Article";
// components
import Loading from "../UI/Loading";
import ArticleForm from "./ArticleForm";

const EditArticle = observer(() => {
  const routerParams = useParams();
  const [article, articleSet] = React.useState<ArticleModel>();
  const [errotMsg, errorMsgSet] = React.useState<string>("");
  const history = useHistory();

  const loadArticle = React.useCallback(async () => {
    if (routerParams.hasOwnProperty("id")) {
      const id: number = (routerParams as any).id;
      const art = await articlesStore.loadArticleById(id);
      articleSet(art);
    }
  }, [routerParams]);

  React.useEffect(() => {
    loadArticle();
  }, [loadArticle]);

  const onFinishHandler = async ({
    id,
    title,
    content,
    image,
    favourite,
  }: ArticleModel): Promise<void> => {
    const msg = await articlesStore.editArticle({
      id: id,
      image: image,
      favourite: favourite,
      content: content,
      title: title,
    });
    if (msg.status === "error") {
      errorMsgSet(msg.message);
    } else {
      history.push("/articles/reload");
    }
  };

  if (loadingStore.loading.article) {
    return <Loading title="Wczytywanie artykułu" />;
  }
  return (
    <>
      {article ? (
        <div>
          <ArticleForm articleData={article!} onFinish={onFinishHandler} />
          <p className="text-red-400 text-center">{errotMsg}</p>
        </div>
      ) : (
        <p className="text-red-400 text-center">
          Błąd - nie ma takiego artykułu!
        </p>
      )}
    </>
  );
});

export default EditArticle;
