import authStore from "../../stores/AuthStore";

export const createDirectory = async (path: string, name: string) => {
  const response = await fetch(
    process.env.REACT_APP_API_BASE_URL +
      "/api/files/mkdir?path=" +
      path +
      "&name=" +
      namePrepare(name),
    {
      method: "POST",
      headers: {
        "x-access-token": authStore.auth.token,
      },
    }
  ).catch((err) => {
    throw err;
  });
  const resData = await response.json().catch((err) => {
    throw err;
  });

  return resData;
};

export const namePrepare = (name: string) => {
  let tmpName = name;
  tmpName = tmpName.toLowerCase();
  tmpName = tmpName.replaceAll("ł", "l");
  tmpName = tmpName.replaceAll("Ł", "l");
  tmpName = tmpName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  tmpName = tmpName.replace(/[^a-z0-9\-`]/gi, "");
  return tmpName;
};
