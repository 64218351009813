import React from "react";
// components
import { SponsorModel } from "../../models/Sponsor";
import FormHeader from "../UI/FormHeader";
import FormInput from "../UI/FormInput";
import FormImage from "../UI/FormImage";
import Button from "../UI/Button";
import FormTextarea from "../UI/FormTextarea";

interface Props {
  sponsorData: SponsorModel;
  onFinish: any;
}

const SponsorForm: React.FC<Props> = ({ sponsorData, onFinish }: Props) => {
  const { id, image, name, description } = sponsorData;
  const [nameForm, nameFormSet] = React.useState<string>(name);
  const [imageForm, imageFormSet] = React.useState<string>(image);
  const [descriptionForm, descriptionFormSet] =
    React.useState<string>(description);
  const [allFields, allFieldsSet] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (nameForm && imageForm && descriptionForm) {
      allFieldsSet(false);
    } else {
      allFieldsSet(true);
    }
  }, [nameForm, imageForm, descriptionForm]);

  const onClickHandler = () => {
    onFinish({
      id: id,
      image: imageForm,
      name: nameForm,
      description: descriptionForm,
    });
  };

  return (
    <div>
      <FormHeader>Nazwa</FormHeader>
      <FormInput
        name="Nazwa"
        value={nameForm}
        set={nameFormSet}
        type="text"
        placeholder="podaj nazwę"
      />
      <FormHeader>Zdjęcie profilowe</FormHeader>
      <FormImage
        key={0}
        image={imageForm}
        imageSet={imageFormSet}
        info="zdjęcie profilowe"
      />
      <FormHeader>Opis sponsora</FormHeader>
      <FormTextarea
        name="Opis"
        value={descriptionForm}
        set={descriptionFormSet}
        placeholder="podaj opis"
      />
      <Button
        name="Zapisz"
        onClick={() => {
          onClickHandler();
        }}
        disabled={allFields}
      />
    </div>
  );
};

export default SponsorForm;
