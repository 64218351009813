import React from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
// stores
import teammembersStore from "../../stores/TeammembersStore";
import loadingStore from "../../stores/LoadingStore";
// components
import Loading from "../UI/Loading";
import TeammemberPreview from "./TeammemberPreview";

const TeammembersList = observer(() => {
  const { pathname } = useLocation();
  const history = useHistory();

  const loadTeammembers = async () => {
    await teammembersStore.loadTeammembers();
  };

  React.useEffect(() => {
    if (pathname === "/teammembers/reload") {
      teammembersStore.reloadTeammembers();
      history.push("/teammembers");
    } else {
      loadTeammembers();
    }
  }, [pathname, history]);

  const TeammembersPreviewList = () => {
    return (
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-1">
        {teammembersStore.teammembers.teammembers.map((item) => (
          <TeammemberPreview teammember={item} key={item.id} />
        ))}
      </div>
    );
  };

  if (loadingStore.loading.teammembers) {
    return <Loading title="Wczytywanie zespołu" />;
  }

  return (
    <div>
      <TeammembersPreviewList />
    </div>
  );
});

export default TeammembersList;
