import { makeAutoObservable, runInAction } from "mobx";
import loadingStore from "./LoadingStore";
import { SponsorModel, SponsorsModel } from "../models/Sponsor";
import authStore from "./AuthStore";

export class SponsorsStore {
  public sponsors: SponsorsModel = {
    sponsors: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  public loadSponsors = async () => {
    loadingStore.loading.sponsors = true;
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/sponsors",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      this.sponsors.sponsors = resData;
      loadingStore.loading.sponsors = false;
    });
  };

  public loadSponsorById = async (sponsorId: number) => {
    loadingStore.loading.sponsor = true;
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/sponsor/" + sponsorId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      loadingStore.loading.sponsor = false;
    });
    return resData[0];
  };

  public addSponsor = async (sponsor: SponsorModel) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/sponsor/add",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
        body: JSON.stringify(sponsor),
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public editSponsor = async (sponsor: SponsorModel) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/sponsor/edit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
        body: JSON.stringify(sponsor),
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public deleteSponsor = async (sponsorId: number) => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/sponsor/delete/" + sponsorId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-access-token": authStore.auth.token,
        },
      }
    ).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    return resData;
  };

  public reloadSponsors = () => {
    this.sponsors.sponsors = [];
  };
}

const sponsorsStore = new SponsorsStore();

export default sponsorsStore;
