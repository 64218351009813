import React from "react";
import FormHeader from "../UI/FormHeader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FavArticle from "./FavArticle";
import Button from "../UI/Button";
import { ArticleModel } from "../../models/Article";
import FormInput from "../UI/FormInput";
import FormImage from "../UI/FormImage";

interface Props {
  articleData: ArticleModel;
  onFinish: any;
}

const ArticleForm: React.FC<Props> = ({ articleData, onFinish }: Props) => {
  const { id, image, title, favourite, content } = articleData;
  const [imageForm, imageFormSet] = React.useState<string>(image);
  const [titleForm, titleFormSet] = React.useState<string>(title);
  const [contentForm, contentFormSet] = React.useState<string>(content);
  const [favouriteForm, favouriteFormSet] = React.useState<number>(favourite);
  const [allFields, allFieldsSet] = React.useState<boolean>(true);

  const editorConfiguration = {
    toolbar: ["bold", "italic", "|", "undo", "redo", "|"],
  };

  React.useEffect(() => {
    if (titleForm && imageForm && contentForm) {
      allFieldsSet(false);
    } else {
      allFieldsSet(true);
    }
  }, [titleForm, imageForm, contentForm]);

  const onClickHandler = () => {
    onFinish({
      id: id,
      image: imageForm,
      favourite: favouriteForm,
      content: contentForm,
      title: titleForm,
    });
  };

  return (
    <div>
      <FormHeader>Tytuł artykułu</FormHeader>
      <FormInput
        name="Tytuł artykułu"
        value={titleForm}
        set={titleFormSet}
        type="text"
        placeholder="podaj tytuł artykułu"
      />
      <FormHeader>Zdjęcie do artykułu</FormHeader>
      <FormImage
        key={0}
        image={imageForm}
        imageSet={imageFormSet}
        info="zdjęcie do artykułu"
      />
      <FormHeader>Treśc artykułu</FormHeader>
      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        key={1}
        onChange={(event: any, editor: any) => {
          contentFormSet(editor.getData());
        }}
        data={contentForm}
      />
      <div className="flex flex-row">
        <FormHeader>Wyróżniony:</FormHeader>
        <FavArticle fav={favouriteForm} favSet={favouriteFormSet} />
      </div>
      <Button
        name="Zapisz"
        onClick={() => {
          onClickHandler();
        }}
        disabled={allFields}
      />
    </div>
  );
};

export default ArticleForm;
