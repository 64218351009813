import React from "react";
import LoginForm from "../components/login/LoginForm";

const AuthScreen = () => {
  return (
    <div className="w-full sm:w-2/3 md:1/2">
      <LoginForm />
    </div>
  );
};

export default AuthScreen;
