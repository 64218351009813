import React from "react";

interface Props {
  name: string;
  value: string;
  set: React.Dispatch<string>;
  type: string;
  placeholder: string;
}

const LoginFormInput: React.FC<Props> = ({
  name,
  value,
  set,
  type,
  placeholder,
}: Props) => {
  return (
    <div>
      <input
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={(event) => set(event.target.value)}
        className="text-gray-700 dark:text-gray-300 text-center text-sm p-2 w-1/2 my-2 bg-transparent border-b-2 border-gray-300 focus:border-gray-700 dark:border-gray-500 dark:focus:border-gray-300 focus:outline-none"
      />
    </div>
  );
};

export default LoginFormInput;
