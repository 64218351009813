import React from "react";

interface Props {
  children: React.ReactNode;
}

const FormHeader: React.FC<Props> = ({ children }: Props) => {
  return (
    <h1 className="text-gray-700 dark:text-gray-300 font-bold text-md mt-4">
      {children}
    </h1>
  );
};

export default React.memo(FormHeader);
