import React from "react";
// components
import MenuLink from "./MenuLink";
import MenuSection from "../components/menu/MenuSection";

const MenuRoutes = () => {
  return (
    <div className="flex flex-col">
      <MenuSection>Wiadomości</MenuSection>
      <MenuLink to="/articles/reload" name="Lista" />
      <MenuLink to="/articles/favourites" name="Wyróżnione" />
      <MenuLink to="/article/add" name="Nowy" />

      <MenuSection>Zespół</MenuSection>
      <MenuLink to="/teammembers" name="Lista" />
      <MenuLink to="/teammember/add" name="Nowy" />

      <MenuSection>Sponsorzy</MenuSection>
      <MenuLink to="/sponsors" name="Lista" />
      <MenuLink to="/sponsor/add" name="Nowy" />
    </div>
  );
};

export default MenuRoutes;
