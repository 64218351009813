import React from "react";
import { FolderOpen } from "react-ionicons";
import { FileModel } from "../../models/File";

interface Props {
  file: FileModel;
  changePath: React.Dispatch<React.SetStateAction<string>>;
  onSetImageUrl: React.Dispatch<React.SetStateAction<string>>;
}

const FileBox: React.FC<Props> = ({
  file,
  changePath,
  onSetImageUrl,
}: Props) => {
  const { name, dir, full_url, url } = file;

  const [clicked, clickedSet] = React.useState<boolean>(false);

  const onClickHandler = () => {
    clickedSet((prev) => !prev);
  };
  const onDoubleClickHandler = () => {
    if (dir) {
      changePath(url);
    } else {
      onSetImageUrl(full_url);
    }
  };

  return (
    <div
      className={`flex w-36 h-36 m-1 p-1 border-2 box-border cursor-pointer ${
        clicked && "border-gray-700"
      }`}
      onClick={onClickHandler}
      onDoubleClick={onDoubleClickHandler}
      onDoubleClickCapture={onDoubleClickHandler}
    >
      <div className="w-full h-full flex flex-col text-center justify-between relative">
        <div className="flex justify-center items-center h-full w-full m-auto">
          {dir ? (
            <FolderOpen width="64px" height="64px" color="#aaaaaa" />
          ) : (
            <img
              src={full_url}
              alt={name}
              className="h-full w-full object-cover"
            />
          )}
        </div>
        <div className="flex text-center justify-center absolute bottom-0 bg-gray-700 w-full">
          <p className="text-xs text-center text-gray-300 truncate">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default FileBox;
