import React from "react";
import { Link } from "react-router-dom";

const NotFoundScreen = () => {
  return (
    <div className="w-full sm:w-2/3 md:w-1/2 text-2xl text-center p-12 m-auto rounded-lg border-2 border-gray-300 text-gray-700 dark:text-gray-300">
      <h1>Tu nic nie ma...</h1>
      <Link to="/" className="hover:underline text-base italic">
        ...wróc do strony głównej
      </Link>
    </div>
  );
};

export default NotFoundScreen;
