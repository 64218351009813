import React from "react";
// components
import ContentRoutes from "../navigation/ContentRoutes";
import MenuBox from "../components/menu/MenuBox";

const HomeScreen = () => {
  return (
    <div className="container flex flex-col md:flex-row lg:place-content-around">
      <div className="w-full md:w-1/4 lg:w-1/5 mx-1 px-1 text-center">
        <MenuBox />
      </div>
      <div className="w-full md:w-3/4 lg:w-3/5 mx-1 px-1">
        <ContentRoutes />
      </div>
    </div>
  );
};

export default HomeScreen;
