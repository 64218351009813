import React from "react";
import { TeammemberModel } from "../../models/Teammember";
import FormHeader from "../UI/FormHeader";
import FormInput from "../UI/FormInput";
import TeammemberActive from "./TeammemberActive";
import FormImage from "../UI/FormImage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "../UI/Button";
import FormGallery from "../UI/FormGallery";

interface Props {
  teammemberData: TeammemberModel;
  onFinish: any;
}

const TeammemberForm: React.FC<Props> = ({
  teammemberData,
  onFinish,
}: Props) => {
  const {
    id,
    image,
    name,
    type,
    intro,
    surname,
    role,
    active,
    description,
    gallery,
  } = teammemberData;
  const [nameForm, nameFormSet] = React.useState<string>(name);
  const [surnameForm, surnameFormSet] = React.useState<string>(surname);
  const [typeForm, typeFormSet] = React.useState<number>(type);
  const [activeForm, activeFormSet] = React.useState<number>(active);
  const [roleForm, roleFormSet] = React.useState<string>(role);
  const [introForm, introFormSet] = React.useState<string>(intro);
  const [imageForm, imageFormSet] = React.useState<string>(image);
  const [descriptionForm, descriptionFormSet] =
    React.useState<string>(description);
  const [galleryForm, galleryFormSet] = React.useState<string[]>(gallery);
  const [allFields, allFieldsSet] = React.useState<boolean>(true);

  const editorConfiguration = {
    toolbar: ["bold", "italic", "|", "undo", "redo", "|"],
  };

  React.useEffect(() => {
    if (
      nameForm &&
      surnameForm &&
      roleForm &&
      introForm &&
      imageForm &&
      descriptionForm &&
      galleryForm.length
    ) {
      allFieldsSet(false);
    } else {
      allFieldsSet(true);
    }
  }, [
    nameForm,
    surnameForm,
    roleForm,
    introForm,
    imageForm,
    descriptionForm,
    galleryForm,
  ]);

  const onClickHandler = () => {
    onFinish({
      id: id,
      image: imageForm,
      name: nameForm,
      type: typeForm,
      intro: introForm,
      surname: surnameForm,
      role: roleForm,
      active: activeForm,
      description: descriptionForm,
      gallery: galleryForm,
    });
  };

  return (
    <div>
      <FormHeader>Imię</FormHeader>
      <FormInput
        name="imię"
        value={nameForm}
        set={nameFormSet}
        type="text"
        placeholder="podaj imię"
      />
      <FormHeader>Nazwisko</FormHeader>
      <FormInput
        name="nazwisko"
        value={surnameForm}
        set={surnameFormSet}
        type="text"
        placeholder="podaj nazwisko"
      />
      <FormHeader>Rodzaj członka zespołu</FormHeader>
      <select
        value={typeForm}
        onChange={(event) => typeFormSet(parseInt(event.target.value))}
        className="text-gray-700 dark:text-gray-300 text-sm flex flex-col p-2 w-full my-2 bg-transparent border-b-2 border-gray-300 focus:border-gray-700 dark:border-gray-500 dark:focus:border-gray-300 focus:outline-none"
      >
        <option
          value={1}
          className="bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
        >
          player
        </option>
        <option
          value={2}
          className="bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
        >
          coach
        </option>
        <option
          value={3}
          className="bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
        >
          staff
        </option>
      </select>
      <div className="flex flex-row">
        <FormHeader>Aktywny:</FormHeader>
        <TeammemberActive active={activeForm} activeSet={activeFormSet} />
      </div>
      <FormHeader>Pozycja (lub pełniona funkcja)</FormHeader>
      <FormInput
        name="pozycja lub rola"
        value={roleForm}
        set={roleFormSet}
        type="text"
        placeholder="podaj pozycję (lub pełnioną funkcję)"
      />
      <FormHeader>Intro</FormHeader>
      <FormInput
        name="intro"
        value={introForm}
        set={introFormSet}
        type="text"
        placeholder="podaj krótką informację o osobie"
      />
      <FormHeader>Zdjęcie profilowe</FormHeader>
      <FormImage
        key={0}
        image={imageForm}
        imageSet={imageFormSet}
        info="zdjęcie profilowe"
      />
      <FormHeader>Opis osoby</FormHeader>
      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        key={1}
        onChange={(event: any, editor: any) => {
          descriptionFormSet(editor.getData());
        }}
        data={descriptionForm}
      />
      <FormHeader>Galeria</FormHeader>
      <FormGallery images={galleryForm} onFinish={galleryFormSet} />
      <Button
        name="Zapisz"
        onClick={() => {
          onClickHandler();
        }}
        disabled={allFields}
      />
    </div>
  );
};

export default TeammemberForm;
