interface PathItemProps {
  name: string;
  path: string;
}

export const pathList = (path: string): PathItemProps[] => {
  const dirs = path === "/" ? ["/"] : path.split("/");

  let dirPath: PathItemProps[] = [];
  let tmpArr: string[] = [];

  dirs.forEach((value, index) => {
    tmpArr.push(value);
    if (index) {
      dirPath.push({
        name: value,
        path: tmpArr.join("/"),
      });
    } else {
      dirPath.push({
        name: "Home",
        path: "/",
      });
    }
  });

  return dirPath;
};
